import React, {useState} from 'react';
/*import {Card, Grid, colors} from "tabler-react";*/
import {ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
/*import "c3/c3.css";*/
import {useEffect} from "react";
import axios from "axios";
import ReactApexChart from 'react-apexcharts';
import VisitCountData from "../data/VisitCountData";
import {dateFormat} from "@hskernel/hs-utils";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./VisitCount.module.css";
import DatePickerComponent from "../common/DatePickerComponent";


const options: ApexCharts.ApexOptions = {
    chart: {
        height: 350,
        type: 'line',
        zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: false
        },
        defaultLocale: 'ko',
        locales: [{
            name: 'ko',
            options: {
                months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                shortMonths: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                days: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
                shortDays: ['일', '월', '화', '수', '목', '금', '토'],
                toolbar: {
                    exportToSVG: "SVG 다운로드",
                    exportToPNG: "PNG 다운로드",
                    exportToCSV: "CSV 다운로드",
                    /*menu: "메뉴",*/
                    selection: "선택",
                    selectionZoom: "선택영역 확대",
                    zoomIn: "확대",
                    zoomOut: "축소",
                    pan: "패닝",
                    reset: "원래대로"
                }
            }
        }]
    },

    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'straight'
    },
    title: {
        text: '',
        align: 'left'
    },
    grid: {
        row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    tooltip: {
        x: {
            format: "yyyy년 MM월 dd일"
        }
    },
    xaxis: {
        type: 'category',
        labels: {
            datetimeUTC: false
        },
        tickAmount: 6,
        categories: []
    },
    series: []
};


const VisitCount = () => {
    const [data, setData] = useState<VisitCountData[]>([]);
    const [chartOption, setChartOption] = useState<ApexCharts.ApexOptions>(options);
    const [dateRange, setDateRange] = useState({
        startDate: new Date().addDays(-7),
        endDate: new Date()
    });

    async function getData(key: string, StartDate: string, EndDate: string): Promise<VisitCountData[] | undefined> {
        try {
            axios.defaults.withCredentials = false;
            const res = await axios.get(`/api/analytics/chart/visit/count/${key}?StartDate=${StartDate}&EndDate=${EndDate}`);
            return res.data;
        } catch (error) {
            console.log("실패")
        }
    }
    function ChartSet(start:string, end:string) {
        getData('cUMTT8m7LWAGJAZPEMKqLtMgEeiuBgo4nVPT8Eyz', start, end)
            .then(datas => {
                datas = datas ?? [];
                setData(datas);
                setChartOption({
                    ...options,
                    series: [{
                        name: '방문자 수',
                        data: datas ? datas.map(data => ({
                                x: dateFormat(new Date(data.date), 'yyyy-MM-dd'),
                                y: data.count,
                            })
                        ):[]
                    }],
                });
            });
    }

    useEffect(() => {
        ChartSet(dateRange.startDate.format("yyyy-mm-dd"),dateRange.endDate.format("yyyy-mm-dd"));
    }, []);

    function datePickerF(start: Date, end: Date) {
        setDateRange({startDate: start, endDate: end});
    }

    return data ?
        <div className={styles.card}>
            <div className={styles.card_header}>방문자 수</div>
            <div className="card-body">
                <div className={styles.date_control_box}>
                    <DatePickerComponent
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    datePickerF={datePickerF}/>
                    <div className={styles.search_btn}>
                        <button onClick={() => ChartSet(dateRange.startDate.format("yyyy-mm-dd"),dateRange.endDate.format("yyyy-mm-dd"))}>검색</button>
                    </div>
                </div>

                {data ? <ReactApexChart options={chartOption} series={chartOption.series} height={350}/> : <p>데이터 로딩중...</p>}
            </div>
        </div>
        :<p>데이터 로딩중</p>;
}

export default VisitCount;