import React, {useState} from 'react';
/*import {Card, Grid, colors} from "tabler-react";*/
import {ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
/*import "c3/c3.css";*/
import {useEffect} from "react";
import axios from "axios";
import ReactApexChart from 'react-apexcharts';
import "react-datepicker/dist/react-datepicker.css";
import styles from "./VisitCount.module.css";
import VisitBrowserData from "../data/VisitBrowserData";
import DatePickerComponent from "../common/DatePickerComponent";


const options: ApexCharts.ApexOptions = {
    chart: {
        width: 380,
        type: 'pie',
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
        },
    }],
    series: [],
    dataLabels: {
        formatter: function (val: any, opts: any) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return `${name} ${val.toFixed(1) + '%'}`;
        }
    }

};


const VisitCount = () => {
    const [data, setData] = useState<VisitBrowserData[]>([]);
    const [chartOption, setChartOption] = useState<ApexCharts.ApexOptions>(options);
    const [dateRange, setDateRange] = useState({
        startDate:  new Date().addDays(-7),
        endDate: new Date()
    });


    async function getData(key: string, StartDate: string, EndDate: string): Promise<VisitBrowserData[] | undefined> {
        try {
            const limit = 100;
            axios.defaults.withCredentials = false;
            const res = await axios.get(`/api/analytics/chart/visit/browser/${key}?StartDate=${StartDate}&EndDate=${EndDate}&Limit=${limit}`);
            return res.data;
        } catch (error) {
            console.log("실패")
        }
    }


    useEffect(() => {
        ChartSet();
    }, []);

    function ChartSet() {
        getData('cUMTT8m7LWAGJAZPEMKqLtMgEeiuBgo4nVPT8Eyz', `${dateRange.startDate.format("yyyy-mm-dd")}`, `${dateRange.endDate.format("yyyy-mm-dd")}`)
            .then(datas => {
                datas = datas ?? [];
                setData(datas);
                setChartOption({
                    ...options,
                    series: datas?.map(data => data.count),
                    labels: datas?.map(data => data.browser)
                });
            });

    }

    function datePickerF(start: Date, end: Date) {
        setDateRange({startDate: start, endDate: end});
    }

    return data ?
        <div className={styles.card}>
            <div className={styles.card_header}>브라우저별 통계</div>
            <div className="card-body">
                <div className={styles.date_control_box}>
                    <DatePickerComponent
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    datePickerF={datePickerF}/>
                    <div className={styles.search_btn}>
                        <button onClick={() => ChartSet()}>검색</button>
                    </div>
                </div>
                <ReactApexChart options={chartOption} series={chartOption.series} type="pie" height={500}/>

            </div>
        </div>
        : <p>데이터 로딩중</p>;
}

export default VisitCount;