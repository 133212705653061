import React, {useState} from 'react';
/*import {Card, Grid, colors} from "tabler-react";*/
import {ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
/*import "c3/c3.css";*/
import {useEffect} from "react";
import axios from "axios";
import {dateFormat, randomKey} from "@hskernel/hs-utils";
import styles from "./VisitCount.module.css";
import VisitPageData from "../data/VisitPageData";
import DatePickerComponent from "../common/DatePickerComponent";
import VisitPathData from "../data/VisitPathData";

const VisitPath = () => {
    const [data, setData] = useState<VisitPathData[]>();
    const [dateRange, setDateRange] = useState({
        startDate: new Date().addDays(-7),
        endDate: new Date(),
    });
    const [page, setPage] = useState({
        currentPage : 1, //현재 페이지
        totalList : 0, //전체 데이터 리스트 수
        currentPagePart : 1 //현재 페이지네이션 파트, 파트는 pageMax 단위로 나눔
    });
    const limit = 100;
    const maxList = 10; //한 페이지에 있는 최대 데이터 리스트 수
    const pageMax = 5; //한번에 보이는 페이지네이션 최대 갯수

    async function getData(key: string, StartDate: string, EndDate: string): Promise<VisitPathData[] | undefined> {
        try {

            axios.defaults.withCredentials = false;
            const res = await axios.get(`/api/analytics/chart/visit/source/${key}?StartDate=${StartDate}&EndDate=${EndDate}&Limit=${limit}`);
            return res.data;
        } catch (error) {
            console.log("실패")
        }
    }


    function DataSet() {
        getData('cUMTT8m7LWAGJAZPEMKqLtMgEeiuBgo4nVPT8Eyz', `${dateRange.startDate.format("yyyy-mm-dd")}`, `${dateRange.endDate.format("yyyy-mm-dd")}`)
            .then(datas => {
                datas = datas ?? [];
                setPage({...page, totalList: datas.length});
                setData(datas);//현재 페이지 데이터만 추출
            });
    } // 데이터 세팅

    function pageChange(id:number) {
        setPage({...page, currentPage: id + 1})
    } //페이지네이션 버튼 클릭시 페이지 바뀜


    useEffect(() => {
        DataSet();
    }, []);

    function datePickerF(start: Date, end: Date) {
        setDateRange({startDate: start, endDate: end});
    }
    return data ?
        <div className={styles.card} style={{width:"fit-content"}}>
            <div className={styles.card_header}>유입경로 통계</div>
            <div className="card-body">
                <div className={styles.date_control_box}>
                    <DatePickerComponent
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        datePickerF={datePickerF}
                    />
                    <div className={styles.search_btn}>
                        <button onClick={() => DataSet()}>검색</button>
                    </div>
                </div>
                <table className={styles.visit_page_table}>
                    <tr>
                        <th className={styles.medium_th}>접속방법</th>
                        <th className={styles.source_th}>접속 사이트</th>
                        <th className={styles.count_th}>방문 수</th>
                    </tr>
                    {
                        data?.slice((page.currentPage-1) * maxList, page.currentPage * maxList ).map((data: any, i) =>
                            <tr key={randomKey(i)}>
                                <td>{data.mediumName}</td>
                                <td>{data.sourceName}</td>
                                <td>{data.count}</td>
                            </tr>
                        )
                    }
                </table>
                <br/>
                <div className={styles.pagination}>
                    <span>[이전]</span>
                    <div className={styles.page_num}>
                        {

                            page.totalList % maxList == 0 ?
                                Array(Math.floor(page.totalList / maxList))
                                    .fill(0)
                                    .map((arr, i) => <a key={randomKey(i)} onClick={() => pageChange(i)}>[{i + 1}]</a>)
                                :
                                Array(Math.floor(page.totalList / maxList) + 1)
                                    .fill(0)
                                    .map((arr, i) => <a key={randomKey(i)} onClick={() => pageChange(i)}>[{i + 1}]</a>)
                        }
                    </div>
                    <span>[다음]</span>
                </div>
            </div>

        </div> : <p>데이터 로딩중</p>;
}

export default VisitPath;