import React from 'react';
import styles from "visit/VisitCount.module.css";
// @ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {ko} from "date-fns/esm/locale";

export type DataPickerProps = {
    startDate: Date,
    endDate: Date,
    datePickerF: (start: Date, end: Date) => void
};

const DatePickerComponent = ({startDate, endDate, datePickerF}: DataPickerProps) => {
    return (
        <>
            <div className={styles.date_control}>
                <span className={styles.start_day}>시작일</span>
                <DatePicker dateFormat='yyyy년 MM월 dd일'
                            className={styles.start_input}
                            shouldCloseOnSelect={true}
                            locale={ko}
                            selected={startDate}
                            placeholderText={`${new Date().getFullYear()}년 ${new Date().getMonth()}월 ${new Date().getDate()}일`}
                            onChange={(date: Date) => datePickerF(date, endDate)}/>
            </div>
            <div className={styles.date_control}>
                <span className={styles.end_day}>종료일</span>
                <DatePicker dateFormat='yyyy년 MM월 dd일'
                            className={styles.end_input}
                            shouldCloseOnSelect={true}
                            locale={ko}
                            selected={endDate}
                            placeholderText={`${new Date().getFullYear()}년 ${new Date().getMonth() + 1}월 ${new Date().getDate()}일`}
                            onChange={(date: Date) => datePickerF(startDate,date)}/>
            </div>
        </>
    )
};

export default DatePickerComponent;