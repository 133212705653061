import React, {useState} from 'react';
/*import {Card, Grid, colors} from "tabler-react";*/
import {ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
/*import "c3/c3.css";*/
import {useEffect} from "react";
import axios from "axios";
import {dateFormat, randomKey} from "@hskernel/hs-utils";
import styles from "./VisitCount.module.css";
import VisitPageData from "../data/VisitPageData";
import DatePickerComponent from "../common/DatePickerComponent";
import VisitReferrerData from "../data/VisitReferrerData";

const VisitReferrer = () => {
    const [data, setData] = useState<VisitReferrerData[]>();
    const [dateRange, setDateRange] = useState({
        startDate: new Date().addDays(-7),
        endDate: new Date()
    });


    async function getData(key: string, StartDate: string, EndDate: string): Promise<VisitReferrerData[] | undefined> {
        try {
            const limit = 10;
            axios.defaults.withCredentials = false;
            const res = await axios.get(`/api/analytics/chart/visit/referrer/${key}?StartDate=${StartDate}&EndDate=${EndDate}&Limit=${limit}`);
            return res.data;
        } catch (error) {
            console.log("실패")
        }
    }


    function ChartSet() {
        getData('cUMTT8m7LWAGJAZPEMKqLtMgEeiuBgo4nVPT8Eyz', `${dateRange.startDate.format("yyyy-mm-dd")}`, `${dateRange.endDate.format("yyyy-mm-dd")}`)
            .then(datas => {
                datas = datas ?? [];
                setData(datas);
            });

    }

    useEffect(() => {
        ChartSet();
    }, []);

    function datePickerF(start: Date, end: Date) {
        setDateRange({startDate: start, endDate: end});
    }

    return data?
        <div className={styles.card}>
            <div className={styles.card_header}>방문자 유입 사이트</div>
            <div className="card-body">

                <div className={styles.date_control_box}>
                    <DatePickerComponent
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        datePickerF={datePickerF}
                    />
                    <div className={styles.search_btn}>
                        <button onClick={() => ChartSet()}>검색</button>
                    </div>
                </div>
                <table className={styles.visit_page_table}>
                    <tr>
                        <th className={styles.pre_th}>이전 사이트 주소</th>
                        <th className={styles.refer_count_th}>방문 수</th>
                    </tr>
                    {
                        data?.map((data: any, i) =>
                            <tr key={randomKey(i)}>
                                <td>{data.referrer? data.referrer : "(없음)"}</td>
                                <td>{data.count}</td>
                            </tr>
                        )
                    }
                </table>
            </div>
        </div>
        : <p>데이터 로딩중</p>;
}

export default VisitReferrer;