import React, {CSSProperties} from 'react';
import {Link} from 'react-router-dom';

const Main = () => {
    const linkDisplay: CSSProperties = {
        display:"block",
        textAlign:"left",
        padding:"10px"
    }
    return(
        <div>
            <Link to={'/visit'} style={linkDisplay}>방문자 수 통계</Link>
             <Link to={'/browser'} style={linkDisplay}>브라우저 별 통계</Link>
             <Link to={'/page'} style={linkDisplay}>최다 방문 페이지</Link>
             <Link to={'/path'} style={linkDisplay}>유입 경로 통계</Link>
             <Link to={'/referrer'} style={linkDisplay}>방문자 유입 사이트</Link>
             <Link to={'/keyword'} style={linkDisplay}>검색 키워드</Link>
        </div>
    )
}

export default Main;