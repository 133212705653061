import * as React from 'react';
import './App.css';
import VisitCount from "./visit/VisitCount";
import VisitBrowser from "./visit/VisitBrowser";
import VisitPage from "./visit/VisitPage";
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Main from "./main/Main";
import VisitPath from "./visit/VisitPath";
import VisitReferrer from "./visit/VisitReferrer";
import VisitKeyword from "./visit/VisitKeyword";

const App: React.FC = () => {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/visit" element={<VisitCount/>}/>
                    <Route path="/browser" element={<VisitBrowser/>}/>
                    <Route path="/page" element={<VisitPage/>}/>
                    <Route path="/path" element={<VisitPath/>}/>
                    <Route path="/referrer" element={<VisitReferrer/>}/>
                    <Route path="/keyword" element={<VisitKeyword/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
